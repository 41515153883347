import React from 'react';
import './Slides2.css';
import sl1 from "../../../images/sl-1.webp";
import sl2 from "../../../images/sl-2.webp";
import sl3 from "../../../images/sl-3.webp";
import sl4 from "../../../images/sl-4.webp";
import sl5 from "../../../images/sl-5.webp";
import sl6 from "../../../images/sl-6.webp";
import sl7 from "../../../images/sl-7.webp";
import sl8 from "../../../images/sl-8.webp";
import sl9 from "../../../images/sl-9.jpeg";
import sl10 from "../../../images/sl-10.jpeg";
import sl11 from "../../../images/sl-11.jpeg";
import sl12 from "../../../images/sl-12.jpeg";
import sl13 from "../../../images/sl-13.jpeg";
import sl14 from "../../../images/sl-14.jpeg";
import sl15 from "../../../images/sl-15.jpeg";
import sl16 from "../../../images/sl-16.jpeg";
import sl17 from "../../../images/sl-17.jpeg"
import sl18 from "../../../images/sl-18.webp";
import sl19 from "../../../images/sl-19.webp";
import sl20 from "../../../images/sl-20.webp";
import sl21 from "../../../images/sl-21.webp";

const Slidesnew2 = () => {
  return (
    <div>
      <div className="center">
        <div className="slider">
          <div className="items2">
            <img src={sl18} className="item" />
            <img src={sl19} className="item" />
            <img src={sl20} className="item" />
            <img src={sl21} className="item" />
            <img src={sl10} className="item" />
            <img src={sl11} className="item" />
            <img src={sl12} className="item" />
            <img src={sl13} className="item" />
            <img src={sl14} className="item" />
            <img src={sl15} className="item" />
            <img src={sl16} className="item" />
            <img src={sl17} className="item" />
            <img src={sl10} className="item" />
            <img src={sl11} className="item" />
            <img src={sl12} className="item" />
            <img src={sl13} className="item" />
            <img src={sl14} className="item" />
            <img src={sl15} className="item" />
            <img src={sl16} className="item" />
            <img src={sl17} className="item" />
            <img src={sl10} className="item" />
            <img src={sl11} className="item" />
            <img src={sl12} className="item" />
            <img src={sl13} className="item" />
            <img src={sl14} className="item" />
            <img src={sl15} className="item" />
            <img src={sl16} className="item" />
            <img src={sl17} className="item" />
            <img src={sl10} className="item" />
            <img src={sl11} className="item" />
            <img src={sl12} className="item" />
            <img src={sl13} className="item" />
            <img src={sl14} className="item" />
            <img src={sl15} className="item" />
            <img src={sl16} className="item" />
            <img src={sl17} className="item" />


            {/* Duplicate the images to ensure continuous looping */}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slidesnew2;
